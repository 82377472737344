
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
const listData = ref();
const listComment = ref();
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router";

export default defineComponent({
  name: "blog-detail",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      description: "",
      dataId: this.$route.params.id,
      images: [
        {
          files: "",
        },
      ],
      imagesPreview: [
        {
          preview: "",
        },
      ],
    };
  },
  setup() {
    const route = useRoute();
    onMounted(() => {
      //   , ['ข่าวสารและบทความ']
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getWebboard?id=" +
            route.params.id +
            "&os=0",
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.content[0]);
          listData.value = response.data.data.content[0];
          setCurrentPageBreadcrumbs(listData.value.title, ["ถามตอบ"]);
        })
        .catch((error) => {
          // console.log(error);
        });
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getWebboardComment?webboardId=" +
            route.params.id,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.content);
          listComment.value = response.data.data.content;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    return {
      getDataList,
      listData,
      listComment,
      store,
      router,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
    async sentComment() {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      await store.dispatch(Actions.VERIFY_AUTH);
      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];
      if (!error) {
        if (this.description && this.dataId) {
          const formData = new FormData();
          formData.append("id", this.dataId + "");
          formData.append("comment", this.description);
          if (this.images.length > 0) {
            for (const loopfile of this.images) {
              formData.append("picturesPath", loopfile.files);
            }
          }
          axios
            .post(
              process.env.VUE_APP_API_URL + "/addWebBoardComment",
              formData,
              {
                headers: {
                  token: localStorage.getItem("id_token")
                },
              }
            )
            .then((res) => {
              Swal.fire({
                title: "บันทึกรายการสำเร็จ",
                text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-primary",
                },
              }).then(function() {
                router.go(0);
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: error.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      } else {
        router.push({ name: "sign-in" });
      }
    },
    clickInputFile() {
      const imagPath: any = document.getElementById("imagPath");
      imagPath.click();
    },
    onFileChange(e) {
      this.imagesPreview = [];
      this.images = [];
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        this.images.push({
          files: files[i],
        });
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const data: any = event.target.result;
            this.imagesPreview.push({ preview: data });
          }
        };
        reader.readAsDataURL(files[i]);
      }
    },
    onClick(element) {
      // console.log(element);
      const img: any = document.getElementById("img");
      const modal: any = document.getElementById("modal");
      img.src = element.target.currentSrc;
      modal.style.display = "block";
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
