<template>
  <!--begin::Dashboard-->
  <div class="p-5 container">
    <div
      class="row mb-5"
      style="padding: 2vh;background-color: #e9e9e9;
    border: 1px solid #d2d2d2;
    border-radius: 10p"
    >
      <p
        style="font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;"
      >
        {{ listData ? listData.title : "" }}
      </p>
      <div
        style="display: flex;
                    justify-content: flex-start;
                    flex-direction: row;
                    align-items: center;"
      >
        <div
          class="symbol symbol-50px symbol-circle"
          style="border: 1px solid #a1a1a1;"
        >
          <img
            :src="
              listData && listData.authorProfile != null
                ? listData.authorProfile
                  ? imageUrl + listData.authorProfile.imageProfile
                  : ''
                : ''
            "
            alt=""
            style="width: 50px;height: 50px;object-fit: cover;"
            @error="setAltImg"
          />
        </div>
        <div class="mx-5">
          <p>
            {{
              listData && listData.authorProfile != null
                ? listData.authorProfile.firstName +
                  " " +
                  listData.authorProfile.lastName
                : "-"
            }}
          </p>
          <p>{{ formatDateTime(listData ? listData.createDate : "") }}</p>
        </div>
      </div>
      <div class="row" v-if="listData && listData.picturesPath">
        <div
          v-for="(image, index) in listData.picturesPath"
          :key="index"
          class="col-12 col-lg-4"
        >
          <img
            @click="onClick($event)"
            :src="imageUrl + image"
            alt=""
            style="width: 100%;
                    height: 200px;
                    border-radius: 10px;
                    object-fit: cover;"
          />
        </div>
      </div>
      <p
        class="mt-2"
        style="font-weight: 400;
                font-size: 16px;
                color: #7F7F81;"
        v-html="listData ? listData.description : ''"
      ></p>
    </div>
    <div class="row">
      <p>ความคิดเห็น</p>
      <hr />
      <div class="row" v-if="listComment">
        <div v-for="(item, index) in listComment" :key="index" class="col-12">
          <div
            class="mt-2"
            style="display: flex;
                    justify-content: flex-start;
                    flex-direction: row;
                    align-items: center;"
          >
            <div
              class="symbol symbol-50px symbol-circle"
              style="border: 1px solid #a1a1a1;"
            >
              <img
                :src="
                  item.authorProfile != null
                    ? imageUrl + item.authorProfile.imageProfile
                    : 'media/avatars/blank.png'
                "
                alt=""
                style="width: 50px;height: 50px;object-fit: cover;"
                @error="setAltImg"
              />
            </div>
            <div class="mx-5">
              <p>
                {{
                   item.authorProfile != null
                    ? item.authorProfile.firstName +
                      " " +
                      item.authorProfile.lastName
                    : "-"
                }}
              </p>
              <p>{{ formatDateTime(item.createDate) }}</p>
            </div>
          </div>
          <p
            class="text-category"
            style="font-weight: 400;
                    line-height: 24px;
                    color: #333333;"
            v-html="item.comment"
          ></p>
          <div class="preview" v-if="item.picturesPath">
            <div v-for="(image, index) in item.picturesPath" :key="index">
              <img v-if="image" :src="imageUrl + image" />
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <div class="row">
      <div style="position: relative;">
        <div class="preview">
          <div v-for="(image, index) in imagesPreview" :key="index">
            <img v-if="image.preview" :src="image.preview" />
          </div>
        </div>
        <div style="position: absolute;bottom: 0;bottom: 2vh;right: 3vh;">
          <button
            type="button"
            @click="clickInputFile()"
            class="btn btn-light-primary mx-2"
          >
            <i class="las la-image"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary mx-2"
            @click="sentComment"
          >
            <i class="las la-paper-plane"></i>
          </button>
        </div>
        <textarea
          v-model="description"
          class="form-control"
          id=""
          cols="30"
          rows="5"
        ></textarea>
      </div>
      <p class="text-danger">กรุณาระบุ</p>
      <input
        type="file"
        id="imagPath"
        @change="onFileChange($event)"
        multiple
        accept="image/*"
        hidden
      />
    </div>
  </div>
  <div id="modal" class="modal" onclick="this.style.display='none'">
    <span class="close">&times;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <div class="modal-content">
      <img id="img" style="max-width:100%" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
const listData = ref();
const listComment = ref();
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router";

export default defineComponent({
  name: "blog-detail",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      description: "",
      dataId: this.$route.params.id,
      images: [
        {
          files: "",
        },
      ],
      imagesPreview: [
        {
          preview: "",
        },
      ],
    };
  },
  setup() {
    const route = useRoute();
    onMounted(() => {
      //   , ['ข่าวสารและบทความ']
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getWebboard?id=" +
            route.params.id +
            "&os=0",
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.content[0]);
          listData.value = response.data.data.content[0];
          setCurrentPageBreadcrumbs(listData.value.title, ["ถามตอบ"]);
        })
        .catch((error) => {
          // console.log(error);
        });
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getWebboardComment?webboardId=" +
            route.params.id,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.content);
          listComment.value = response.data.data.content;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    return {
      getDataList,
      listData,
      listComment,
      store,
      router,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
    async sentComment() {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      await store.dispatch(Actions.VERIFY_AUTH);
      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];
      if (!error) {
        if (this.description && this.dataId) {
          const formData = new FormData();
          formData.append("id", this.dataId + "");
          formData.append("comment", this.description);
          if (this.images.length > 0) {
            for (const loopfile of this.images) {
              formData.append("picturesPath", loopfile.files);
            }
          }
          axios
            .post(
              process.env.VUE_APP_API_URL + "/addWebBoardComment",
              formData,
              {
                headers: {
                  token: localStorage.getItem("id_token")
                },
              }
            )
            .then((res) => {
              Swal.fire({
                title: "บันทึกรายการสำเร็จ",
                text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-primary",
                },
              }).then(function() {
                router.go(0);
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: error.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      } else {
        router.push({ name: "sign-in" });
      }
    },
    clickInputFile() {
      const imagPath: any = document.getElementById("imagPath");
      imagPath.click();
    },
    onFileChange(e) {
      this.imagesPreview = [];
      this.images = [];
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        this.images.push({
          files: files[i],
        });
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const data: any = event.target.result;
            this.imagesPreview.push({ preview: data });
          }
        };
        reader.readAsDataURL(files[i]);
      }
    },
    onClick(element) {
      // console.log(element);
      const img: any = document.getElementById("img");
      const modal: any = document.getElementById("modal");
      img.src = element.target.currentSrc;
      modal.style.display = "block";
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
</script>
<style scoped>
.preview {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
}

.preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin: 5px;
}
.modal {
  z-index: 1;
  display: none;
  padding-top: 10px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  margin: auto;
  display: block;
  position: absolute;
  width: 80%;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-hover-opacity {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-backface-visibility: hidden;
}

.modal-hover-opacity:hover {
  opacity: 0.6;
  filter: alpha(opacity=60);
  -webkit-backface-visibility: hidden;
}

.close {
  text-decoration: none;
  float: right;
  font-size: 24px;
  font-weight: bold;
  color: white;
}
.container1 {
  width: 200px;
  display: inline-block;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>
